import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import md5 from 'js-md5';
import jwt_decode from 'jwt-decode';
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
Vue.prototype.$jwt_decode = jwt_decode;
Vue.prototype.$md5 = md5;
Vue.use(ElementUI);
Vue.config.productionTip = false
router.beforeEach(async (to, from, next) => {
  if(to.matched[0]) {
      document.title = to.matched[0].meta.title || '登录-Mysitefy';
  }
  next();
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
