import Vue from "vue";
import VueRouter from 'vue-router';
Vue.use(VueRouter)
export default new VueRouter({
    mode: "history",
    routes: [
        { path: '/', redirect: '/login', meta:{title:'登录-Mysitefy'}},
        { path: '/login', name: 'login', component: ()=> import( "@/view/account/index.vue"), meta:{title:'登录-Mysitefy'}},
        { path: '/logins', name: 'logins', component: ()=> import( "@/view/login/index.vue"),meta:{title:'登录-Mysitefy'} },
        { path: '/register', name: 'register', component: ()=> import( "@/view/register/index.vue"),meta:{title:'注册-Mysitefy'} },
        { path: '/resetPassword', name: 'resetPassword', component: ()=> import( "@/view/reset/reset.vue"),meta:{title:'登录-Mysitefy'} },
        { path: '/chooseTeam', name: 'chooseTeam', component: ()=> import( "@/view/chooseTeam/index.vue"),meta:{title:'登录-Mysitefy'} },
        { path: '/entrance', name: 'entrance', component: ()=> import( "@/view/entrance/index.vue"),meta:{title:'迷你站'} },
        { path: '/regisers', name: 'regisers', component: ()=> import( "@/view/regisers/index.vue"),meta:{title:'迷你站'} },
    ]
})