import Vue  from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// import ajax from "../js/ajax";
// import { setStorage } from "@/utils/main.js";
const store = new Vuex.Store({
    state: () => ({
    }),
    mutations: {
    },
    actions: {
    }
})

export default store